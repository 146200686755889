/* Loader container */
.loaderContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

/* Background fade overlay */
.fadeOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease, background-color 0.5s ease;
  z-index: 1000;
}

/* Fade-in animation */
.fadeOverlay.fadeIn {
  overflow: hidden;
  animation: fadeIn 0.5s ease forwards;
}
.contentFadeIn {
  animation: contentFadeIn 0.2s ease forwards;
}

/* Fade-out animation */
.fadeOverlay.fadeOut {
  overflow: hidden;
  animation: fadeOut 0.5s ease forwards;
}
.contentFadeOut {
  animation: contentFadeOut 0.2s ease forwards;
}

/* Keyframes for fadeIn */
@keyframes fadeIn {
  0% {
    opacity: 0;
    background-color: var(--gray-tint-bgcolor);
  }
  100% {
    opacity: 1;
    background-color: var(--white-color);
  }
}
@keyframes contentFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Keyframes for fadeOut */
@keyframes fadeOut {
  0% {
    opacity: 1;
    background-color: var(--gray-tint-bgcolor);
  }
  100% {
    opacity: 0;
    background-color: var(--white-color);
    display: none;
  }
}

@keyframes contentFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
