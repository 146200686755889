:root {
  /* ---------- theme colors ---------- */
  --primary-color: #256aff !important;
  --primary-light-color: #e6eeff !important;
  --primary-tint-color: #f4f8ff !important;
  --primary-dark-color: #0049e6 !important;

  --secondary-color: #671602 !important;
  --secondary-light-color: #9d3704 !important;
  --secondary-tint-color: #000000 !important;
  --secondary-dark-color: #e0e0e0 !important;
}
